jQuery(document).ready(function ($) {
    function handleAccordionClick(accordionSelector, contentSelector) {
        $(accordionSelector).on('click', function () {
            const title = $(this);
            const description = title.find(contentSelector);

            // Check if the clicked accordion is already active
            const isActive = title.hasClass('active');

            // Hide all open accordions
            $(accordionSelector).removeClass('active');
            $(contentSelector).slideUp(300);

            // If the clicked accordion was not already active, show it
            if (!isActive) {
                title.addClass('active');
                description.slideDown(300);
            }
        });
    }

    handleAccordionClick('.module-accordion-form__accordion', '.module-accordion-form__accordion__content');
    handleAccordionClick('.request-a-call-back-list', '.request-a-call-back-list-description');
});